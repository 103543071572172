export class AsphaltPortalAuth {
  /** URL pathname permission configuration */
  public static readonly urlPathnames: string[] = [
    '/',
    '/orders/*',
    '/internal-transfers/*',
    '/products',
    '/terminals',
    '/users/*',
    '/recipes',
    '/login',
  ];

  static canView(permissions: string[], key: string) {
    switch (key) {
      case '/orders/*':
      case '/products':
        return permissions.includes('ORDERSADMIN') || permissions.includes('ORDERSUSER');
      case '/terminals':
        return permissions.includes('BLENDSADMIN') || permissions.includes('ORDERSADMIN');
      case '/users/*':
      case '/internal-transfers/*':
        return permissions.includes('ORDERSADMIN');
      case '/recipes':
        return permissions.includes('BLENDSUSER');
      case '/login':
        return true;
      default:
        return false;
    }
  }

  static getAccess(permissions: string[], key: string) {
    const isBlendsUser = permissions.includes('BLENDSUSER') || permissions.includes('BLENDSADMIN');
    const isBlendsAdmin = permissions.includes('BLENDSADMIN');
    const isOrdersUser = permissions.includes('ORDERSUSER') || permissions.includes('ORDERSADMIN');
    const isOrdersAdmin = permissions.includes('ORDERSADMIN');
    const canView = this.canView(permissions, key);

    return {
      isBlendsUser,
      isBlendsAdmin,
      isOrdersUser,
      isOrdersAdmin,
      canView,
    };
  }

  static includesOA(permissions: string[]) {
    return permissions.includes('ORDERSADMIN');
  }

  static includesOU(permissions: string[]) {
    return permissions.includes('ORDERSUSER');
  }

  static includesBA(permissions: string[]) {
    return permissions.includes('BLENDSADMIN');
  }

  static includesBU(permissions: string[]) {
    return permissions.includes('BLENDSUSER');
  }
}
