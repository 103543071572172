import { Box } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { CustomGridToolbarCreateButton, CustomGridToolbarDeleteButton } from '@components';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { OutletContext } from '@shared/types';

type UserToolbarProps = {
  selectedRows: any[];
  handleDeleteClick: () => void;
};

export const UserToolbar = ({ selectedRows, handleDeleteClick }: UserToolbarProps) => {
  const navigate = useNavigate();
  const { isOrdersAdmin = false } = useOutletContext<OutletContext>();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        {isOrdersAdmin && (
          <>
            <CustomGridToolbarCreateButton onClick={() => navigate('/users/create', { state: { newUser: true } })} />
            <GridToolbarExport />
          </>
        )}
        {selectedRows.length > 0 && isOrdersAdmin && <CustomGridToolbarDeleteButton onClick={handleDeleteClick} />}
      </GridToolbarContainer>
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    </Box>
  );
};
