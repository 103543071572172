import { TextField, TextFieldProps } from '@mui/material';
import { validatePhoneNumberInput } from '@util';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

type ControlledPhoneInputProps = Omit<ControllerProps<any>, 'render'> & TextFieldProps;
export const ControlledPhoneInput = (props: ControlledPhoneInputProps) => {
    const { control, name, label, required, rules, ...rest } = props;

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                validate: {
                    validPhoneNumber: (value) => {
                        if (!value) return true;
                        return validatePhoneNumberInput(value as string) || 'Invalid phone number';
                    },
                },
            }}
            render={({ field, fieldState }) => {
                return (
                    <TextField
                        {...field}
                        {...rest}
                        label={label}
                        size="small"
                        type={'tel'}
                        sx={{ m: 1, minWidth: 200 }}
                        helperText={fieldState.error?.message ?? ` `}
                        error={!!fieldState.error}
                        required={required}
                        placeholder="123-456-1234"
                        value={field.value || ''}
                        onChange={(params) => {
                            if (params.target.value) {
                                field.onChange(params.target.value);
                            } else {
                                field.onChange(null);
                            }
                        }}
                    />
                );
            }}
        />
    );
};
