import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { resources } from '@shared/constants';

export const buildRecipeEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    getRecipes: builder.query<any, { recipeType: 'prompt' | 'inventory' | 'opt'; terminal: number }>({
        query: (params) => ({
            url: `${resources.RECIPE}`,
            params: params,
        }),
        providesTags: [],
    }),
    getRecipesByProduct: builder.query<any, { terminal: number; product: number; recipeType: 'non' }>({
        //Blends page
        query: (params) => ({
            url: `${resources.RECIPE}`,
            params: params,
        }),
        providesTags: [],
    }),
    getPromptRecipes: builder.query<any, { recipeType: 'prompt'; terminal: string }>({
        query: (params) => ({
            url: `${resources.RECIPE}`,
            params: params,
        }),
    }),
    getInventoryRecipes: builder.query<any, { recipeType: 'inventory'; terminal: string }>({
        query: (params) => ({
            url: `${resources.RECIPE}`,
            params: params,
        }),
    }),
    saveRecipe: builder.mutation<
        AnyObject,
        {
            recipeType: 'prompt' | 'inventory' | 'opt';
            terminal: string;
            product: string;
            startDate: string;
            endDate: string;
            ingredients: { ingredient: string; percentage: number }[];
            email: string;
        }
    >({
        query: (payload) => ({
            url: `${resources.RECIPE}`,
            method: 'POST',
            body: payload,
        }),
        invalidatesTags: [],
    }),
});
