import Oidc from 'oidc-client';
import { StorageHelper } from './storage';
import { AnyObject } from '@fhr/react';
import { User } from './user';
import { UserIdentity } from './user';

export type AuthStatus = 'ERROR' | 'PENDING' | 'SUCCESS' | 'UNKNOWN';
export type TokenStatus = 'INVALID' | 'MISSING' | 'VALID';

export interface AuthProviderConfig {
    unauthorizedRoute?: string;
    useHashHistory?: boolean;
    logLevel?: number;
    endSsoOnLogout?: boolean;
}

/**
 * Base class for a React Admin Auth Provider
 */
export abstract class BaseAuthProvider {
    protected unauthorizedRoute: string;
    protected useHashHistory: boolean;
    protected logger: Oidc.Logger;
    protected endSsoOnLogout: boolean;
    protected resolveFinish: () => void = () => undefined;
    protected endSSOUrl = 'https://u4fnahnd0k.execute-api.us-east-1.amazonaws.com/prod/api/v1/endSSO';
    public finished =
        this.getAuthStatus() === 'SUCCESS' ? Promise.resolve() : new Promise<void>((res) => (this.resolveFinish = res));

    /**
     * Constructs a new Auth Provider
     * @param config Provider configuration
     */
    constructor(config?: AuthProviderConfig) {
        this.unauthorizedRoute = config?.unauthorizedRoute || '/unauthorized';
        this.useHashHistory = config?.useHashHistory === true;
        this.endSsoOnLogout = config?.endSsoOnLogout === true;
        this.logger = Oidc.Log;
    }

    /**
     * Called by React Admin when it wants to check auth
     * @param params Data about the auth check
     */
    public async checkAuth(params: AnyObject) {
        this.logger.debug(`${BaseAuthProvider.name}.${this.checkAuth.name}`, params);
        const tokenStatus = await this.getTokenStatus();

        // redirect to login route if token is missing
        if (tokenStatus === 'MISSING') {
            return Promise.reject();
        }

        // redirect to unauthorized route if token is invalid
        if (tokenStatus === 'INVALID') {
            return Promise.reject({ redirectTo: this.unauthorizedRoute });
        }
        return Promise.resolve();
    }

    /**
     * Called by React Admin when it wants to know what to do with an error
     * @param params Data about the error
     */
    public async checkError(params: AnyObject) {
        this.logger.debug(`${BaseAuthProvider.name}.${this.checkError.name}`, params);

        // compute the correct status
        let status = params.status || 500;
        const tokenStatus = await this.getTokenStatus();

        // 403-Unauthorized. If authenticated bail here and let react-admin deal with it
        if (status === 403 && tokenStatus === 'VALID') {
            return;
        }

        // 401-Unauthenticated. If token is missing cause a login to happen else treat like a 403
        if (status === 401 && tokenStatus !== 'MISSING') {
            status = 403;
        }
        if (status === 401) {
            return Promise.reject(false);
        }

        // 403-UnAuthorized. Redirect to unauthorized route
        if (status === 403) {
            this.redirect(this.unauthorizedRoute);
        }
        // don't handle the error and let react-admin deal with it
        return;
    }

    /**
     * Gets the current auth status
     */
    public getAuthStatus() {
        return StorageHelper.getItem<AuthStatus>(localStorage, 'authStatus') || 'UNKNOWN';
    }

    /**
     * Called by React Admin when it wants a list of permissions
     */
    public async getPermissions() {
        let result: string[] = [];
        const profile = await this.getUserProfile();
        if (profile) {
            if (typeof profile.permissions === 'string') {
                result = [profile.permissions];
            } else {
                result = profile.permissions || [];
            }
        }
        this.logger.debug(`${BaseAuthProvider.name}.${this.getPermissions.name}`, result);
        return result;
    }

    /**
     * Gets the current access token
     */
    public async getToken(): Promise<string> {
        const user = await this.getUser();
        return user ? `${user.tokenType} ${user.accessToken}` : '';
    }

    /**
     * Gets the status of access token
     */
    public async getTokenStatus() {
        let result: TokenStatus = 'MISSING';
        const user = await this.getUser();
        if (user) {
            const now = Date.now() / 1000;
            result = user.expiresAt - now <= 0 ? 'INVALID' : 'VALID';
        } else if (this.getAuthStatus() === 'ERROR') {
            result = 'INVALID';
        }
        this.logger.debug(`${BaseAuthProvider.name}.${this.getTokenStatus.name}`, result);
        return result;
    }

    /**
     * Gets the current user's profile information
     */
    public async getUserProfile() {
        const user = await this.getUser();
        const result = user ? user.profile : undefined;
        this.logger.debug(`${BaseAuthProvider.name}.${this.getUserProfile.name}`, result);
        return result;
    }

    /**
     * Gets the current user's information
     */
    public async getIdentity(): Promise<UserIdentity> {
        const profile = await this.getUserProfile();
        return profile || { id: '' };
    }

    /**
     * Initializes the auth provider
     * @param config Configuration information to use
     */
    public abstract initialize(config: AnyObject): Promise<string[]>;

    /**
     * Called by React Admin when it wants to start the login process
     * @param params Data about the error
     */
    public abstract login(params?: AnyObject): Promise<any>;

    /**
     * Called by React Admin when it wants to start the logout process
     */
    public abstract logout(): Promise<void | string>;

    protected abstract getUser(): Promise<User | undefined>;

    protected recordError(error: Error | string | undefined) {
        this.setAuthStatus('ERROR');
        StorageHelper.setItem(localStorage, 'authError', error);
    }

    protected redirect(route?: string) {
        console.log('redirecting to', route);
        window.location.replace(`${this.useHashHistory === true ? '#' : ''}${route || '/'}`);
    }

    protected setAuthStatus(value: AuthStatus) {
        if (value === 'SUCCESS') {
            this.resolveFinish();
        }
        StorageHelper.setItem(localStorage, 'authStatus', value || 'UNKNOWN');
    }

    protected filterClaims(claims: AnyObject, ...extra: string[]) {
        const result = Object.assign({}, claims);
        ['nonce', 'at_hash', 'iat', 'nbf', 'exp', 'aud', 'iss', 'c_hash', ...extra].forEach((c) => delete result[c]);
        return result;
    }

    protected async endSso() {
        const token = await this.getToken();
        const user = await this.getUser();
        if (token && user && user.profile.piSri && this.endSsoOnLogout) {
            await fetch(this.endSSOUrl, {
                headers: new Headers({ Authorization: token }),
                method: 'POST',
                body: JSON.stringify({ piSri: user.profile.piSri }),
            });
        }
    }
}
