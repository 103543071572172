import { useState, useEffect } from 'react';
import { useAppContext } from './useAppContext';
import { Profile } from '../auth/profile';

export function useCognitoProfile() {
    const [profile, setProfile] = useState<Profile | undefined>(undefined);
    const { authProvider } = useAppContext();

    useEffect(() => {
        if (authProvider) {
            authProvider.getUserProfile().then((result: Profile | undefined) => {
                setProfile(result);
            });
        }
    }, [authProvider]);

    return profile;
}
