import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { EventBusPublishData } from '@features/recipes/types';
import { resources } from '@shared/constants';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildEventBusEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    event_sendToEventBus: builder.mutation<AnyObject, EventBusPublishData>({
        query: (payload) => ({
            url: `${resources.EVENT}`,
            method: 'POST',
            body: {data: payload },
        }),
        invalidatesTags: [],
    }),
    event_sendToEventBusSecondary: builder.mutation<
        AnyObject,
        { newRecipeVersion: string; v1: { value: EventBusPublishData } }
    >({
        query: (payload) => {
            const { newRecipeVersion, v1 } = payload;
            const { value } = v1;
            return {
                url: `${resources.EVENT}`,
                method: 'POST',
                body:  { data: value },
            };
        },
        invalidatesTags: [],
    }),
});
