import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { resources } from '@shared/constants';
import { DestinationRecord } from '@shared/types';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildDestinationEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    getDestinations: builder.query<DestinationRecord[], void | AnyObject>({
        query: (params) => ({
            url: `${resources.DESTINATION}`,
            params: params,
        }),
    }),
});
