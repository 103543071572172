export type OrderForm = {
  OrderId: number;
  Company?: BusinessAssociate;
  BaNumber: number;
  OrderedBy?: string;
  OrderedByLastName: string;
  OrderedByFirstName: string;
  PhoneNumber: string;
  Email: string;
  Terminal: number;
  Acknowledgement: string;
  SalesAgreementNumber: number;
  Carrier: string;
  DeliveryDate: string;
  DestinationId: number;
  Destination: string;
  Product: number;
  RequestType: RequestType | '';
  RequestAmount: number | null;
  AmountLoaded: number | null;
  Comments: string;
  DeliveredLoadFlag: string;
  JobDescription: string;
  Status: string;
  StatusFlag: 1 | 0;
  DestNotInList: 1 | 0;
  Balance?: number;
  RemainingTons?: number;
};

export type OrderedBy = {
  FirstName: string;
  LastName: string;
  FullName: string;
};

export type BusinessAssociate = {
  CNT_PRTY_BA_NO: number;
  BA_BA_SHORT_NM: string;
  BA_BA_LONG_NM?: string;
};

export type Order = {
  OrderId?: number;
  CompanyName: string;
  BaNumber: number;
  OrderedByFirstName: string;
  OrderedByLastName: string;
  PhoneNumber: string;
  Email: string;
  Terminal: number;
  SalesAgreementNumber: number;
  Carrier: string;
  DeliveryDate: string;
  TradeStartDate?: string;
  TradeEndDate?: string;
  DestinationId: number;
  Destination: string;
  Product: number;
  RequestType: RequestType | '';
  RequestAmount: number;
  Comments: string;
  DeliveredLoadFlag: string;
  JobDescription: string;
  Status: string;
  Acknowledgement: string;
  AmountLoaded: number;
  StatusFlag: 1 | 0;
  DestNotInList: 1 | 0;
  OrderedBy: string;
  RemainingTons?: number;
  ProductShortName?: string;
  ProductDescription?: string;
  CustomerShortName?: string;
  CustomerLongName?: string;
  TerminalShortName?: string;
  TemrinalLongName?: string;
  LoadNote?: string;
  Balance: number;
};

export type RDSOrder = Omit<Order, 'DeliveryDate'> & { DeliveryDate: Date };

export type UpdateOrder = Omit<
  Order,
  | 'RemainingTons'
  | 'OrderedBy'
  | 'ProductShortName'
  | 'ProductDescription'
  | 'CustomerShortName'
  | 'CustomerLongName'
  | 'TerminalShortName'
  | 'TemrinalLongName'
  | 'TradeEndDate'
  | 'TradeStartDate'
  | 'LoadNote'
  | 'Balance'
>;

export type RequestType = 'Tons' | 'Gallons' | 'Full Load';

export type FriendlyOrder = {
  'Company Name': string | null;
  'First Name': string | null;
  'Last Name': string | null;
  'Phone Number': string | null;
  Email: string | null;
  'BA Number': string | null;
  Terminal: string | null;
  Acknowledgement: string | null;
  'Sales Agreement Number': string | null;
  Carrier: string | null;
  'Delivery Date': string | null;
  Destination: string | null;
  Product: string | null;
  'Request Type': string | null;
  'Request Amount': string | null;
  'Delivered Load Flag': string | null;
  JobDescription: string | null;
  Status: string | null;
  Comments: string | null;
  'Status Flag': string | null;
  'Destination Not In List?': string | null;
};

export enum DynamicFilterFields {
  STATUS = 'Status',
  TERMINAL = 'Terminal',
  PRODUCT = 'Product',
  DELIVERY_DATE = 'Delivery Date',
  ORDERED_BY = 'Ordered By',
}

export enum BoolFlag {
  False = 0,
  True = 1,
}
