import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { User } from '@shared/types';
import { resources } from '@shared/constants';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildUsersEndpoints = (builder: EndpointBuilder<any, TagType, any>) => ({
    getUsers: builder.query<User[], void | AnyObject>({
        query: (params) => ({
            url: `${resources.USERS}`,
            params: params,
        }),
        providesTags: ['Users'],
    }),

    postUser: builder.mutation<any, void | AnyObject>({
        query: (body) => ({
            url: `${resources.USERS}`,
            method: 'POST',
            body: body,
        }),
        invalidatesTags: ['Users'],
    }),

    patchUser: builder.mutation<any, { id: string } & Partial<Omit<User, 'id'>>>({
        query: (body) => {
            const { id, ...rest } = body;
            return {
                url: `${resources.USERS}/${id}`,
                method: 'PATCH',
                body: rest,
            };
        },
        invalidatesTags: ['Users'],
    }),

    putUser: builder.mutation<any, User>({
        query: (body) => {
            const { id, ...rest } = body;
            return {
                url: `${resources.USERS}/${id}`,
                method: 'PUT',
                body: rest,
            };
        },
        invalidatesTags: ['Users'],
    }),

    deleteUser: builder.mutation<any, string>({
        query: (id) => ({
            url: `${resources.USERS}/${id}`,
            method: 'DELETE',
        }),
        invalidatesTags: ['Users'],
    }),
});
