import React from 'react';
import { appRoutes } from './appRoutes';

//look into code-splitting: https://beta.reactjs.org/reference/react/lazy
//Best to start with just pages
const LoginPage = React.lazy(() => import('./pages/login/LoginPage'));
const Page = React.lazy(() => import('./pages/root/Page'));

export const getRoutes = () => {
  return [
    {
      path: '/',
      label: 'Home',
      element: <Page />,
      children: [...appRoutes()],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
  ];
};
