import { Route } from '@fhr/react';
import { orderRoutes } from './orderRoutes';
import { terminalRoutes } from './terminalRoutes';
import { userRoutes } from './userRoutes';
import { productRoutes } from './productRoutes';
import { recipeRoutes } from './recipeRoutes';

export const appRoutes = (): Route[] => {
    return [...orderRoutes(), ...terminalRoutes(), ...userRoutes(), ...productRoutes(), ...recipeRoutes()];
};
