import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { tagTypes } from './tags';
import { appDomain } from '../app-domain';
import {
    buildOrdersEndpoints,
    buildProductsEndpoints,
    buildTransactionInfoEndpoints,
    buildTerminalDetailEndpoints,
    buildRecipeEndpoints,
    buildTerminalEndpoints,
    buildDestinationEndpoints,
    buildEventBusEndpoints,
    buildUsersEndpoints,
    buildCustomersEndpoints,
    buildRecipeProductEndpoints,
    buildDateEndpoints,
    buildOptimizationEndpoints,
} from './endpoints';
import { CognitoHelper } from './cognitoHelper';

//Docs: https://dev.azure.com/flinthills/FHR/_wiki/wikis/FHR.wiki/876/Calling-your-API
export const api = createApi({
    reducerPath: 'Api',
    tagTypes: tagTypes,
    baseQuery: fetchBaseQuery({
        baseUrl: `https://${appDomain}/api/v1`,
        credentials: 'include',
        prepareHeaders: async (headers) => {
            const cognito = new CognitoHelper();
            const token = await cognito.getToken();
            headers.set('Authorization', token);
            headers.set('X-App-Version', '1.0');
            return headers;
        },
    }),
    endpoints: (builder) => ({
        //Add your endpoints here
        //Docs: https://redux-toolkit.js.org/rtk-query/usage/queries - Defining Query Endpoints
        //Example: https://github.com/FlintHills/Solutions.Docs?path=/example/web/src/api/api.ts&version=GBmain&_a=contents

        ...buildTerminalDetailEndpoints(builder),
        ...buildOrdersEndpoints(builder),
        ...buildTransactionInfoEndpoints(builder),
        ...buildProductsEndpoints(builder),
        ...buildUsersEndpoints(builder),
        ...buildCustomersEndpoints(builder),

        //Recipes
        ...buildRecipeEndpoints(builder),
        ...buildRecipeProductEndpoints(builder),
        ...buildTerminalEndpoints(builder),
        ...buildDestinationEndpoints(builder),
        ...buildEventBusEndpoints(builder),
        ...buildDateEndpoints(builder),
        ...buildOptimizationEndpoints(builder),
    }),
});
