import { Backdrop, BackdropProps, CircularProgress } from '@mui/material';
import React from 'react';

type ActionInProgressProps = BackdropProps;

export const ActionInProgress = ({ open, ...rest }: ActionInProgressProps) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} {...rest}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
