import { darken, lighten, styled } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';

export const getRowBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8);

const getRowHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

export const StyledOrderGrid = styled(DataGridPro)(({ theme }) => {
  return {
    '& .super-app-theme--LoadNote': {
      backgroundColor: getRowBackgroundColor(theme.palette.warning.main, theme.palette.mode),
      '&:hover': {
        backgroundColor: getRowHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
      },
      '&.Mui-selected': {
        backgroundColor: getSelectedBackgroundColor(theme.palette.warning.main, theme.palette.mode),
        '&:hover': {
          backgroundColor: getSelectedHoverBackgroundColor(theme.palette.warning.main, theme.palette.mode),
        },
      },
    },
  };
});
