import { Box } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { OutletContext } from '@shared/types';

export const ProductToolbar = () => {
  const { isOrdersAdmin = false, isBlendsAdmin = false } = useOutletContext<OutletContext>();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        {(isOrdersAdmin || isBlendsAdmin) && <GridToolbarExport />}
      </GridToolbarContainer>
      <GridToolbarContainer>
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    </Box>
  );
};
