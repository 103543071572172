import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { Order, UpdateOrder } from '@shared/types';
import { resources } from '@shared/constants';
import { BaseQueryFn } from '@reduxjs/toolkit/query';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildOrdersEndpoints = (builder: EndpointBuilder<BaseQueryFn, TagType, string>) => ({
    getOrders: builder.query<Order[], void | AnyObject>({
        query: (params) => ({
            url: `${resources.ORDERS}`,
            params: params,
        }),
        providesTags: ['Orders'],
    }),

    getOrder: builder.query<Order, number>({
        query: (OrderId) => `${resources.ORDERS}/${OrderId}`,
        providesTags: ['Orders'],
    }),

    postOrder: builder.mutation<any, UpdateOrder>({
        query: (body) => ({
            url: `${resources.ORDERS}`,
            method: 'POST',
            body: body,
        }),
        invalidatesTags: ['Orders'],
    }),

    patchOrder: builder.mutation<any, { OrderId?: number } & Partial<Omit<Order, 'OrderId'>>>({
        query: (body) => {
            const { OrderId, ...rest } = body;
            return {
                url: `${resources.ORDERS}/${OrderId}`,
                method: 'PATCH',
                body: rest,
            };
        },
        invalidatesTags: ['Orders'],
    }),

    deleteOrder: builder.mutation<any, number>({
        query: (id) => ({
            url: `${resources.ORDERS}/${id}`,
            method: 'DELETE',
        }),
        invalidatesTags: ['Orders'],
    }),
});
