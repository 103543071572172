export class StorageHelper {
    public static getItem<T>(provider: Storage, key: string, initialValue?: T, raw?: boolean): T | undefined {
        try {
            const value = localStorage.getItem(key);
            if (typeof value !== 'string') {
                provider.setItem(key, raw ? String(initialValue) : JSON.stringify(initialValue));
                return initialValue;
            } else {
                return raw ? value : JSON.parse(value || 'null');
            }
        } catch {
            // If user is in private mode or has storage restriction
            // localStorage can throw. JSON.parse and JSON.stringify
            // can throw, too.
            return initialValue;
        }
    }

    public static setItem<T>(provider: Storage, key: string, state: T, raw?: boolean) {
        try {
            if (state) {
                provider.setItem(key, raw ? String(state) : JSON.stringify(state));
            }
        } catch {
            // If user is in private mode or has storage restriction
            // localStorage can throw. Also JSON.stringify can throw.
        }
    }

    public static removeItem(provider: Storage, key: string) {
        provider.removeItem(key);
    }
}
