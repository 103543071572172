export const resources = {
    ORDERS: 'orders',
    PRODUCTS: 'products',
    USERS: 'users',
    TRADEDETAIL: 'tradedetail',
    DESTINATION: 'destination',
    TRANSACTIONINFO: 'transactioninfo',
    TERMINALDETAIL: 'terminaldetail',
    BANUMBER: 'banumber',
    TERMINAL: 'terminal',
    RECIPE: 'recipe',
    OPTIMIZATION: 'optimization',
    RECIPEPRODUCT: 'recipeproduct',
    REMOVEPRODUCT: 'removeProduct',
    DATE: 'date',
    COMPONENTS: 'components',
    RECIPEBYPRODUCT: 'recipebyproduct',
    EVENT: 'event',
};
