import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import { CognitoAuthProvider } from './auth';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import * as serviceWorker from './serviceWorker';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { getMuiXProLicense } from '@fhr/react';
import { Toaster } from 'react-hot-toast';

const container = document.getElementById('root');
if (container === null) throw new Error('Root container missing in index.html');
const root = createRoot(container);

export interface AuthProviderConfig {
    unauthorizedRoute?: string;
    useHashHistory?: boolean;
    logLevel?: number;
    endSsoOnLogout?: boolean;
}

//Learn more about @fhr/react library https://github.com/FlintHills/NPM.React
async function initialize() {
    // get auth provider
    const authConfig: AuthProviderConfig = {
        unauthorizedRoute: '/login',
        useHashHistory: false,
        endSsoOnLogout: false,
    };

    const authProvider = new CognitoAuthProvider(authConfig);

    /* For some reason Auth in Amplify is null causing maplibre to fail to get credentials.
         This just set Auth so maplibre doesn't throw a type error. */
    Amplify.Auth = Auth;

    const {
        VITE_COGNITO_REGION: region,
        VITE_AUTHORITY: authority,
        VITE_CLIENT_ID: client_id,
        VITE_COGNITO_DOMAIN: domain,
        VITE_COGNITO_IDENTITY_POOL_ID: identityPoolId,
        VITE_COGNITO_USER_POOL_ID: userPoolId,
        VITE_COGNITO_USER_POOL_WEB_CLIENT_ID: userPoolWebClientId,
    } = import.meta.env;
    const auth = {
        type: 'cognito',
        authFlow: 'code' as const,
        authority,
        client_id,
        domain,
        identityPoolId,
        userPoolId,
        userPoolWebClientId,
        region,
    };
    localStorage.setItem('config', JSON.stringify({ auth: auth }));

    // finish authorization
    const permissions = await authProvider.initialize(auth);

    LicenseInfo.setLicenseKey(getMuiXProLicense());

    return { authProvider };
}

export const AppContext = React.createContext<{ authProvider: CognitoAuthProvider } | null>(null);

initialize().then((props) => {
    root.render(
        <StrictMode>
            <AppContext.Provider value={props}>
                <Toaster />
                <App />
            </AppContext.Provider>
        </StrictMode>
    );

    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
});
