import { Container } from '@mui/material';
import React from 'react';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import { ProductToolbar } from './ProductToolbar';
import { useProductPrefetch } from '@hooks';

const headers = {
  ProductNumber: 'Product Number',
  ProductName: 'Product Name',
};

const columns: GridColDef[] = [];
Object.keys(headers).forEach((key) => {
  columns.push({
    field: key,
    headerName: headers[key],
    width: 200,
  });
});

export const ProductList = () => {
  const { data: products = [], isFetching: isFetchingProducts } = useProductPrefetch();

  return (
    <>
      <Container maxWidth="xl" sx={{ maxWidth: 3000 }}>
        {/* Data */}
        <DataGridPro
          rows={products}
          columns={columns}
          pageSizeOptions={[15]}
          slots={{
            toolbar: ProductToolbar,
          }}
          sx={{ m: 1, gridArea: 'main', height: 'calc(100vh - 64px)' }}
          loading={isFetchingProducts}
        />
      </Container>
    </>
  );
};

export default ProductList;
