import { Menu } from '@fhr/react';
import { AccountCircle } from '@mui/icons-material';
import React from 'react';
import { useCognitoProfile } from '../../hooks/useCognitoProfile';
import { useAppContext } from '@hooks';

export const Logout = () => {
    const context = useAppContext();
    const profile = useCognitoProfile();

    return (
        <Menu
            label={profile?.fullName}
            items={[{ label: 'Logout', action: context.authProvider.logout }]}
            icon={AccountCircle}
        />
    );
};
