import { Autocomplete, AutocompleteProps, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, ControllerProps } from 'react-hook-form';

type ControlledAutoCompleteProps = Omit<ControllerProps<any>, 'render'> &
    Partial<AutocompleteProps<any, any, any, any>> &
    Omit<TextFieldProps, 'onChange'> & { valueProperty?: string };

export const ControlledAutoComplete = (props: ControlledAutoCompleteProps) => {
    const {
        name,
        rules,
        loading,
        options,
        getOptionLabel,
        required,
        label,
        sx,
        isOptionEqualToValue,
        control,
        disabled,
        valueProperty,
        helperText,
        freeSolo,
        autoSelect,
        ...rest
    } = props;
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, fieldState }) => {
                return (
                    <Autocomplete
                        {...field}
                        id={`${name.toLowerCase()}-select`}
                        loading={loading}
                        disabled={disabled ?? false}
                        onChange={(...params) => {
                            const [, v] = params;
                            rest.onChange && rest.onChange(...params);
                            if (v) {
                                field.onChange(valueProperty ? v[valueProperty] : v);
                            } else {
                                field.onChange(null);
                            }
                        }}
                        options={options ?? []}
                        getOptionLabel={getOptionLabel}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                required={required}
                                label={label}
                                variant="outlined"
                                size="small"
                                helperText={helperText !== undefined ? helperText : fieldState.error?.message ?? ` `}
                            />
                        )}
                        sx={sx ?? { m: 1, minWidth: 200 }}
                        isOptionEqualToValue={isOptionEqualToValue}
                        value={
                            valueProperty
                                ? options?.find((o) => o[valueProperty] === field.value) ?? null
                                : field.value ?? null
                        }
                        freeSolo={freeSolo ?? false}
                        autoSelect={autoSelect ?? false}
                    />
                );
            }}
        />
    );
};
