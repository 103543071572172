export const requestTypeChoices = [
    { id: 'Gallons', name: 'Gallons' },
    { id: 'Full Load', name: 'Full Load' },
    { id: 'Tons', name: 'Tons' },
];

export const yesNoChoices = [
    { id: 'Y', name: 'Yes' },
    { id: 'N', name: 'No' },
];

export const statusFlags = [
    { id: '1', name: 'Open' },
    { id: '0', name: 'Completed' },
];
