import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OrderForm, BoolFlag } from '@shared/types';
import { OrderDetails } from './OrderDetails';
import { useParams } from 'react-router-dom';
import { api } from '@api';
import { skipToken } from '@reduxjs/toolkit/query';

const defaultValues: OrderForm = {
  OrderId: 0,
  SalesAgreementNumber: 0,
  Acknowledgement: '',
  BaNumber: 0,
  Carrier: '',
  AmountLoaded: null,
  Comments: '',
  Company: undefined,
  DeliveredLoadFlag: 'N',
  DeliveryDate: '',
  Destination: '',
  DestinationId: 0,
  DestNotInList: BoolFlag.False,
  Email: '',
  JobDescription: '',
  OrderedByFirstName: '',
  OrderedByLastName: '',
  PhoneNumber: '',
  Product: 0,
  RequestAmount: null,
  RequestType: '',
  Status: '',
  StatusFlag: BoolFlag.False,
  Terminal: 0,
};

export const OrderCreateForm = () => {
  const { id } = useParams();
  const { data: order, isFetching: isFetchingOrder } = api.endpoints.getOrder.useQuery(id ? +id : skipToken);

  const methods = useForm<OrderForm>({
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (order) {
      methods.reset({
        ...defaultValues,
        ...order,
        DestNotInList: order.DestNotInList === null ? BoolFlag.False : order.DestNotInList,
        Company: { CNT_PRTY_BA_NO: order.BaNumber, BA_BA_SHORT_NM: order.CompanyName },
        Destination: order.DestNotInList === BoolFlag.True ? order.Destination : order.DestinationId?.toString() || '',
        DeliveryDate: order.DeliveryDate?.split('T')[0] || '',
      });
    }
  }, [order]);

  return (
    <FormProvider {...methods}>
      <OrderDetails isFetchingOrder={isFetchingOrder} />
    </FormProvider>
  );
};
