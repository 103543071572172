import { NavigationPanel } from '@components';
import { sideNavSet } from '@fhr/react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { useDispatch, useSelector } from '@redux-hooks';
import React from 'react';
import { useIsDesktopView } from '../../hooks/useIsDesktopView';

const MENU_DRAWER_WIDTH = 275;

export function SideBar() {
    const isDesktop = useIsDesktopView();
    const dispatch = useDispatch();
    const navMenuOpen = useSelector(({ navMenuOpen }) => navMenuOpen);

    /**
     * Certain swipe feature must be disabled on iOS devices
     * @see https://mui.com/material-ui/react-drawer/#swipeable
     */
    const iOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);

    const handleNavBarOpen = (value: boolean) => {
        dispatch(sideNavSet(value));
    };

    return (
        <React.Fragment>
            <SwipeableDrawer
                id="side-bar"
                anchor="left"
                open={navMenuOpen}
                onClose={() => handleNavBarOpen(false)}
                onOpen={() => handleNavBarOpen(true)}
                disableBackdropTransition={!iOS}
                disableDiscovery={iOS || isDesktop}
                disableSwipeToOpen={iOS || isDesktop}
            >
                <Box
                    width={MENU_DRAWER_WIDTH}
                    role="presentation"
                    onClick={() => handleNavBarOpen(false)}
                    onKeyDown={() => handleNavBarOpen(false)}
                    height="100vh"
                >
                    <NavigationPanel />
                </Box>
            </SwipeableDrawer>
        </React.Fragment>
    );
}
