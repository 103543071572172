import { AnyObject } from '@fhr/react';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { TagType } from '../tags';
import { BusinessAssociate } from '@shared/types';
import { resources } from '@shared/constants';
import { BaseQueryFn } from '@reduxjs/toolkit/query';

// For more information on how to create an api see: https://redux-toolkit.js.org/rtk-query/overview
export const buildCustomersEndpoints = (builder: EndpointBuilder<BaseQueryFn, TagType, string>) => ({
    getCustomers: builder.query<BusinessAssociate[], void | AnyObject>({
        query: (params) => ({
            url: `${resources.BANUMBER}`,
            params: params,
        }),
    }),
});
